import React from "react"
import ReactDOM from "react-dom"
// import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { AuthStateProvider } from "./contexts/AuthStateContextProvider"
import { BrowserRouter } from "react-router-dom"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import CssBaseline from "@mui/material/CssBaseline"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import { theme } from "./utils/muiTheme"
import { Provider } from "react-redux"
import { store } from "./store"

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <AuthStateProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthStateProvider>
        </Provider>
      </ThemeProvider>
    </CssBaseline>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
