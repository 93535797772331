import { createSlice } from "@reduxjs/toolkit"

const zoneSlice = createSlice({
  name: "zones",
  initialState: {
    selectedZone: null,
  },
  reducers: {
    setSelectedZone: (state, action) => {
      state.selectedZone = action.payload
    },
  },
})

export const { setSelectedZone } = zoneSlice.actions
export default zoneSlice.reducer
