import React, { Fragment } from "react"

import ClockIcon from "@mui/icons-material/AccessTimeOutlined"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material"

const HoursComponent = ({ hours }) => {
  const today = new Date().getDay()

  return (
    <>
      <div className="fs-5 text-start text-uppercase fw-light mb-4">
        <Box
          sx={{
            marginBottom: 4,
            textTransform: "uppercase",
            fontWeight: 300,
            display: "flex",
            alignItems: "center",
          }}
        >
          <ClockIcon />
          <Typography variant="h5" sx={{ fontWeight: "300", ml: 3 }}>
            Openingsuren
          </Typography>
        </Box>
      </div>
      <Table
        size="small"
        sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }}
      >
        <TableBody>
          {hours.map((h) => (
            <TableRow
              selected={h.day === today}
              key={h.id}
              sx={{
                "& td": {
                  fontWeight: "300",
                  color: "#99999",
                  fontSize: 16,
                },
                "&.Mui-selected td": {
                  backgroundColor: "#6dae45",
                  fontWeight: "700",
                  color: "white",
                },
              }}
              className={
                h.day === today ? "fw-bold text-primary" : "text-muted fw-light"
              }
            >
              <TableCell>{h.label}</TableCell>
              {!h.isOpen ? (
                <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                  Gesloten
                </TableCell>
              ) : (
                h.blocks.map((b) => (
                  <Fragment key={b.id}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {b.openTime}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {b.closeTime}
                    </TableCell>
                  </Fragment>
                ))
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default HoursComponent
