import React from "react"

const MapComponent = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?key=AIzaSyBCpT_EDAvyRAPhSbcj1nVlFOXBs0SnuQg&pb=!1m18!1m12!1m3!1d2513.294722417924!2d4.140068416380826!3d50.95525497954829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c395e91716cb1d%3A0x6ad325478aa34157!2sFrituur%20&#39;t%20dorp!5e0!3m2!1sen!2sbe!4v1639602487337!5m2!1sen!2sbe"
      height="400"
      width="100%"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Maps"
    ></iframe>
  )
}

export default MapComponent
