import { Stack, TextField } from "@mui/material"
import React from "react"

const OrderForm = ({ formik }) => {
  const { values, touched, errors, handleChange } = formik

  return (
    <Stack gap={2}>
      <TextField
        fullWidth
        id="firstName"
        name="firstName"
        label="Voornaam"
        value={values.firstName}
        onChange={handleChange}
        error={touched.firstName && Boolean(errors.firstName)}
        helperText={touched.firstName && errors.firstName}
      />
      <TextField
        fullWidth
        id="lastName"
        name="lastName"
        label="Achternaam"
        value={values.lastName}
        onChange={handleChange}
        error={touched.lastName && Boolean(errors.lastName)}
        helperText={touched.lastName && errors.lastName}
      />
      <TextField
        fullWidth
        id="email"
        name="email"
        label="E-mail"
        value={values.email}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <TextField
        fullWidth
        id="phone"
        name="phone"
        label="Telefoonnummer"
        value={values.phone}
        onChange={handleChange}
        error={touched.phone && Boolean(errors.phone)}
        helperText={touched.phone && errors.phone}
      />
      <TextField
        fullWidth
        id="street"
        name="street"
        label="Straat"
        value={values.street}
        onChange={handleChange}
        error={touched.street && Boolean(errors.street)}
        helperText={touched.street && errors.street}
      />
      <TextField
        id="number"
        name="number"
        label="Nummer"
        value={values.number}
        onChange={handleChange}
        error={touched.number && Boolean(errors.number)}
        helperText={touched.number && errors.number}
      />
      <TextField
        id="bus"
        name="bus"
        label="Bus"
        value={values.bus}
        onChange={handleChange}
        error={touched.bus && Boolean(errors.bus)}
        helperText={touched.bus && errors.bus}
      />
      <TextField
        id="postalCode"
        name="postalCode"
        label="Postcode"
        value={values.postalCode}
        disabled
        type="number"
      />
      <TextField
        id="city"
        name="city"
        label="Stad"
        value={values.city}
        disabled
      />
      <TextField
        fullWidth
        id="comments"
        name="comments"
        label="Opmerkingen"
        multiline
        minRows={4}
        value={values.comments}
        onChange={handleChange}
        error={touched.comments && Boolean(errors.comments)}
        helperText={touched.comments && errors.comments}
      />
    </Stack>
  )
}

export default OrderForm
