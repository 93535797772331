import { Box, IconButton, Paper, Stack, Typography } from "@mui/material"
import React, { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"

import DeleteIcon from "@mui/icons-material/DeleteOutline"
import { removeProduct } from "../store/cart"

const CartContent = ({ isEditable = true }) => {
  const { items } = useSelector((state) => state.cart)
  const dispatch = useDispatch()

  return (
    <Stack gap={4}>
      {items.map((it) => (
        <Fragment key={it.id}>
          <Paper
            elevation={3}
            key={it.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              minHeight: "100px",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {it.qty}x
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box mr={2}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", textAlign: "right" }}
                >
                  {it.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "300", textAlign: "right" }}
                >
                  {it.extras.map((ex) => ex.name).join(", ")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    flexShrink: 0,
                    textAlign: "right",
                  }}
                >
                  {(it.price / 100).toFixed(2).replace(".", ",")} &euro;
                </Typography>
              </Box>
              {isEditable && (
                <IconButton
                  color="red"
                  onClick={() => dispatch(removeProduct(it.id))}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Paper>
        </Fragment>
      ))}
    </Stack>
  )
}

export default CartContent
