import React from "react"

import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import Typography from "@mui/material/Typography"

import Select from "react-select"
import { selectListStyle } from "../utils/selectStyles"

const ExtrasOptions = (props) => {
  const { extras, setSelectedExtras, selectedExtras } = props

  return (
    <Box sx={{ mb: 4 }}>
      {extras.map((eg) => (
        <FormControl component="form" key={eg.id} fullWidth sx={{ my: 1 }}>
          <Typography
            component="legend"
            variant="overline"
            sx={{ fontWeight: "700", color: "primary.main" }}
          >
            {eg.name}
          </Typography>
          {eg?.max && (
            <FormHelperText>
              <Typography variant="caption">
                Maximaal {eg?.max} keuze(s)
              </Typography>
            </FormHelperText>
          )}
          {eg?.isRequired && (
            <FormHelperText>
              <Typography variant="caption">
                Verplicht minstens 1 keuze te maken
              </Typography>
            </FormHelperText>
          )}
          <Select
            styles={selectListStyle}
            isMulti={eg?.isMulti}
            placeholder={eg?.placeholder}
            noOptionsMessage={() => "Geen extras gevonden"}
            options={eg?.extras}
            getOptionLabel={(o) => o.name}
            formatOptionLabel={(o, settings) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {o.name}
                </Typography>
                {settings.context === "menu" && !eg?.included && o.price > -10 && (
                  <Typography variant="body1" sx={{ fontWeight: 300 }}>
                    + {(o.price / 100).toFixed(2).replace(".", ",")} &euro;
                  </Typography>
                )}
              </Box>
            )}
            getOptionValue={(o) => o.id}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            hideSelectedOptions={false}
            isClearable
            value={selectedExtras.get(eg.id)?.extras ?? []}
            isOptionDisabled={() =>
              eg?.max && eg?.max <= selectedExtras.get(eg.id).extras.length
            }
            onChange={(option) =>
              setSelectedExtras(
                (prevExtras) =>
                  new Map(
                    prevExtras.set(eg.id, {
                      ...prevExtras.get(eg.id),
                      extras: option ? (eg?.isMulti ? option : [option]) : [],
                    })
                  )
              )
            }
          />
        </FormControl>
      ))}
    </Box>
  )
}

export default ExtrasOptions
