import React from "react"

import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import { useSelector } from "react-redux"

const CartFooter = ({ setOpen }) => {
  const { totalPrice, totalItems } = useSelector((state) => state.cart)

  return (
    <Box
      onClick={() => totalItems > 0 && setOpen(true)}
      sx={{
        position: "sticky",
        bgcolor: "primary.main",
        bottom: 0,
        display: "flex",
        justifyContent: totalItems === 0 ? "center" : "space-between",
        py: 3,
        px: 8,
        borderTop: 16,
        borderColor: "white",
        cursor: totalItems > 0 ? "pointer" : "not-allowed",
        userSelect: "none",
      }}
    >
      {totalItems > 0 ? (
        <>
          <Box display="flex">
            <Typography variant="h5" sx={{ mr: 1, fontWeight: "bold" }}>
              {totalItems}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "300" }}>
              {totalItems === 1 ? "product" : "producten"}
            </Typography>
          </Box>

          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {(totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
          </Typography>
        </>
      ) : (
        <Typography variant="h5">Geen producten</Typography>
      )}
    </Box>
  )
}

export default CartFooter
