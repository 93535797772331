import React from "react"

import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

import PlusIcon from "@mui/icons-material/Add"
import Typography from "@mui/material/Typography"

const ProductComponent = ({
  product,
  setSelectedProduct,
  setExtrasDialogOpen,
}) => {
  return (
    <Box
      sx={{
        minHeight: "75px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", fontSize: 20 }}
          className="fw-bold fs-5"
        >
          {product.name}
        </Typography>
        {product.description && (
          <Typography
            sx={{ fontWeight: "300" }}
            className="text-muted fw-light"
          >
            {product.description}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Button
          size="large"
          variant="contained"
          startIcon={<PlusIcon />}
          onClick={() => {
            setSelectedProduct(product)
            setExtrasDialogOpen(true)
          }}
          sx={{
            minWidth: "120px",
            color: "white",
            fontWeight: "600",
            flexShrink: 0,
          }}
        >
          {(product.price / 100).toFixed(2).replace(".", ",")} &euro;
        </Button>
      </Box>
    </Box>
  )
}

export default ProductComponent
