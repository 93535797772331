// GREEN

export const selectListStyle = {
  control: (provided, state) => ({
    ...provided,
    height: 45,
    minHeight: 45,
    borderColor: state.isFocused ? "#6dae45" : "#ced4da",
    "&:hover": { borderColor: "#6dae45" },
    boxShadow: "none",
    // boxShadow: state.isFocused
    //   ? "0 0 0 .25rem rgba(109, 174, 69, .25)"
    //   : "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "black",
    padding: 10,
    zIndex: 99999,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 99999,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled
      ? "#ced4da"
      : state.isSelected
      ? "white"
      : state.isFocused
      ? "white"
      : undefined,
    backgroundColor: state.isDisabled
      ? undefined
      : state.isSelected
      ? "#6dae45"
      : state.isFocused
      ? "#6dae45"
      : undefined,
    ":active": {
      ...provided[":active"],
      backgroundColor: !state.isDisabled
        ? state.isSelected
          ? "#6dae45"
          : "#6dae45"
        : undefined,
    },
  }),
  multiValue: (provided, state) => {
    return {
      ...provided,
      color: "white",
      backgroundColor: "#6dae45",
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: "white",
      fontSize: 16,
    }
  },
}
