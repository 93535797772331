import { green, lightBlue, orange, red } from "@mui/material/colors"
import createTheme from "@mui/material/styles/createTheme"

export const theme = createTheme({
  palette: {
    primary: {
      main: "#6dae45",
      dark: "#3c6825",
    },
    secondary: {
      main: "#1a1a1a",
    },
    red: {
      main: red[500],
    },
    error: {
      main: red[500],
    },
    success: {
      main: green[500],
    },
    info: {
      main: lightBlue[300],
    },
    warning: {
      main: orange[300],
    },
  },
})
