import ImageListItem from "@mui/material/ImageListItem"
import ImageList from "@mui/material/ImageList"
import React from "react"

import Photo1 from "../assets/img/photo1.jpg"
import Photo2 from "../assets/img/photo2.jpg"
import Photo3 from "../assets/img/photo3.jpg"
import Photo4 from "../assets/img/photo4.jpg"
import Photo5 from "../assets/img/photo5.jpg"

const ImagesList = () => {
  return (
    <ImageList cols={2} sx={{ transform: "translateZ(0)" }}>
      <ImageListItem cols={2}>
        <img src={Photo5} alt="Frituur 't dorp" loading="lazy" />
      </ImageListItem>
      <ImageListItem>
        <img src={Photo1} alt="Frituur 't dorp" loading="lazy" />
      </ImageListItem>
      <ImageListItem>
        <img src={Photo2} alt="Frituur 't dorp" loading="lazy" />
      </ImageListItem>
      <ImageListItem>
        <img src={Photo3} alt="Frituur 't dorp" loading="lazy" />
      </ImageListItem>
      <ImageListItem>
        <img src={Photo4} alt="Frituur 't dorp" loading="lazy" />
      </ImageListItem>
    </ImageList>
  )
}

export default ImagesList
