import React, { useState } from "react"
import CartDialog from "../components/CartDialog"
import CartFooter from "../components/CartFooter"
import CategoryListGroup from "../components/CategoryListGroup"

const CatalogScreen = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <CartDialog open={open} setOpen={setOpen} />
      <CategoryListGroup />
      <CartFooter setOpen={setOpen} />
    </>
  )
}

export default CatalogScreen
