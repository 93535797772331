import React, { useEffect } from "react"
import Container from "@mui/material/Container"
import MapComponent from "../components/MapComponent"
import WelcomeComponent from "../components/WelcomeComponent"
import ImagesList from "../components/ImagesList"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../firebase"
import { useState } from "react"

const HomeScreen = () => {
  const [zones, setZones] = useState([])

  useEffect(() => {
    getDocs(
      query(collection(db, "zones"), where("isEnabled", "==", true))
    ).then((qs) =>
      setZones(
        qs.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
      )
    )
  }, [])

  return (
    <>
      <Container
        maxWidth="lg"
        style={{ paddingTop: "120px", userSelect: "none" }}
      >
        <WelcomeComponent zones={zones} />
        <ImagesList />
      </Container>
      <MapComponent />
    </>
  )
}

export default HomeScreen
