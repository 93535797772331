import React, { useEffect } from "react"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { useMediaQuery, useTheme } from "@mui/material"

import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { useSelector } from "react-redux"
import CartContent from "./CartContent"
import { useNavigate } from "react-router-dom"

const CartDialog = ({ open, setOpen }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const { totalPrice, totalItems } = useSelector((state) => state.cart)
  const {
    selectedZone: { minAmount },
  } = useSelector((state) => state.zones)

  const navigate = useNavigate()
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (totalItems === 0) {
      setOpen(false)
    }
  }, [totalItems])

  const isEnoughToOrder = minAmount <= totalPrice

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      sx={{ userSelect: "none" }}
    >
      <DialogTitle sx={{ textTransform: "uppercase", fontWeight: "300" }}>
        Winkelmand
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CartContent />
      </DialogContent>
      <DialogActions
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Box sx={{ textAlign: "right", mt: 4, mb: 4 }}>
          <Box>
            <Typography variant="overline">Totaal</Typography>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {(totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
            </Typography>
          </Box>

          {!isEnoughToOrder && (
            <Typography variant="body2" textAlign="right">
              Minimum bestelbedrag is{" "}
              {(minAmount / 100).toFixed(2).replace(".", ",")} &euro;
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Sluit
          </Button>
          <Button
            size="large"
            disabled={!isEnoughToOrder}
            variant="contained"
            color="primary"
            sx={{
              color: "white",
            }}
            endIcon={<ChevronRightIcon />}
            onClick={() => {
              navigate("/confirm")
              setOpen(false)
            }}
          >
            Bestel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default CartDialog
