import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useMediaQuery, useTheme } from "@mui/material"

import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../firebase/index"

import QtyStepper from "./QtyStepper"
import { addProduct } from "../store/cart/index"
import ExtrasOptions from "./ExtrasOptions"
import DialogHeader from "./DialogHeader"

const ExtrasDialog = (props) => {
  const { open, setOpen, selectedProduct, setSelectedProduct } = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const dispatch = useDispatch()

  const [tempPrice, setTempPrice] = useState(0)
  const [qty, setQty] = useState(1)
  const [extras, setExtras] = useState([])
  const [selectedExtras, setSelectedExtras] = useState(
    new Map(selectedProduct?.extraGroups?.map((eg) => [eg.id, { extras: [] }]))
  )

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!open) {
      setTempPrice(0)
      setQty(1)
      setSelectedExtras((s) => new Map(s.clear()))
      setSelectedProduct(null)
    }
  }, [open])

  const handleAddToCart = () => {
    dispatch(
      addProduct({
        name: selectedProduct.name,
        size: null,
        price: tempPrice,
        extras: Array.from(selectedExtras.values())
          .map((e) => e.extras)
          .flat()
          .map((e) => {
            return {
              id: e.id,
              name: e.name,
              order: e.order,
              price: e.price ?? 0,
            }
          }),
        qty: qty,
        categoryOrder: selectedProduct.categoryOrder,
        order: selectedProduct.order,
      })
    )
    handleClose()
  }

  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.extraGroups) {
        const extraGroups = selectedProduct.extraGroups
        console.log("AGAIN FIRESTORE")
        const q = query(
          collection(db, "products"),
          where(
            "relatedTags",
            "array-contains-any",
            extraGroups.map((ex) => ex.id)
          ),
          where("isEnabled", "==", true)
        )

        getDocs(q).then((qs) => {
          const extrs = qs.docs.map((ds) => {
            return { ...ds.data(), id: ds.id }
          })

          const cats = extraGroups.map((ex) => {
            return {
              ...ex,
              extras: extrs.filter((e) => e.relatedTags.includes(ex.id)),
            }
          })

          console.log(cats)

          setSelectedExtras(
            new Map(
              cats.map((eg) => [
                eg.id,
                { extras: eg?.isRequired ? eg.extras[0] : [] },
              ])
            )
          )

          setExtras(cats)
        })
      }
    }
  }, [selectedProduct])

  useEffect(() => {
    // Update temp price with the new qty
    if (selectedProduct !== null) {
      let extrasPrice = 0
      if (selectedExtras.size) {
        extrasPrice = Array.from(selectedExtras.values())
          .filter((se) => !se.included)
          .map((se) => se.extras)
          .flat()
          .reduce((prev, curr) => prev + (curr.price ?? 0), 0)
      }
      setTempPrice(selectedProduct?.price + extrasPrice)
    }
  }, [qty, selectedProduct, selectedExtras])

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      sx={{ userSelect: "none" }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p>{selectedProduct?.name}</p>
        <p>{((tempPrice * qty) / 100).toFixed(2).replace(".", ",")} &euro;</p>
      </DialogTitle>
      <DialogContent sx={{ minHeight: "500px" }}>
        <QtyStepper qty={qty} setQty={setQty} />
        {selectedProduct?.extraGroups && selectedProduct.extraGroups.length && (
          <>
            <DialogHeader label="Extras" />
            <ExtrasOptions
              extras={extras}
              selectedExtras={selectedExtras}
              setSelectedExtras={setSelectedExtras}
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ mb: 2 }}>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          onClick={handleClose}
        >
          Annuleer
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          sx={{ color: "white" }}
          onClick={handleAddToCart}
        >
          Voeg toe
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExtrasDialog
