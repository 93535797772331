import "./App.css"

import { Routes, Route } from "react-router-dom"
import FooterComponent from "./components/FooterComponent"
import HomeScreen from "./screens/HomeScreen"
import CatalogScreen from "./screens/CatalogScreen"
import Header from "./components/Header"
import ConfirmScreen from "./screens/ConfirmScreen"
import ConfirmationScreen from "./screens/ConfirmationScreen"

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="catalog" element={<CatalogScreen />} />
        <Route path="confirm" element={<ConfirmScreen />} />
        <Route path="confirmation/:id" element={<ConfirmationScreen />} />
      </Routes>
      <FooterComponent />
    </>
  )
}

export default App
