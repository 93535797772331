import { Box, Divider, Typography } from "@mui/material"
import React from "react"

const DialogHeader = ({ label }) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="overline" sx={{ fontWeight: "300" }}>
        {label}
      </Typography>
      <Divider />
    </Box>
  )
}

export default DialogHeader
