import React, { useEffect, useState } from "react"

import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import EnvelopeIcon from "@mui/icons-material/MailOutline"
import FaceBookIcon from "@mui/icons-material/Facebook"

import { collection, getDocs, orderBy, query } from "firebase/firestore"
import { db } from "../firebase"
import HoursComponent from "./HoursComponent"
import { Link, Typography } from "@mui/material"

const yearNow = new Date().getFullYear()

const FooterComponent = () => {
  const [hours, setHours] = useState([])

  useEffect(() => {
    getDocs(query(collection(db, "hours"), orderBy("order"))).then((qs) =>
      setHours(
        qs.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
      )
    )
  }, [])

  return (
    <Container
      maxWidth={false}
      sx={{ borderTop: 4, borderColor: "primary.main", paddingY: 4 }}
    >
      <Grid container spacing={2} sx={{ padding: 4 }}>
        <Grid item xs={12} md={6} lg={7} xl={8}>
          <Box
            sx={{
              marginBottom: 4,
              textTransform: "uppercase",
              fontWeight: 300,
              display: "flex",
              alignItems: "center",
            }}
          >
            <EnvelopeIcon />
            <Typography variant="h5" sx={{ fontWeight: "300", ml: 3 }}>
              Contact
            </Typography>
          </Box>
          <Box className="text-start fw-light">
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              't Dorp
            </Typography>
            Baardegem-dorp 33,
            <br></br>
            9310 Baardegem
            <br></br>
            <Link href="tel:+32478934521" color="inherit" underline="none">
              0478 93 45 21
            </Link>
            <br></br>
            <br></br>
            BE 0863.088.875
            <br></br>
            <Link href="mailto:info@tdorp-baardegem.be" underline="none">
              info@tdorp-baardegem.be
            </Link>
            <br></br>
            <Link
              href="https://www.facebook.com/FrituurBroodjesBaardegem"
              target="_blank"
              color="inherit"
              rel="noreferrer"
            >
              <FaceBookIcon sx={{ fontSize: 56 }} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={5} xl={4}>
          <HoursComponent hours={hours} />
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        sx={{ textAlign: "center", fontWeight: "300" }}
      >
        &copy; {yearNow} - BreckxIT
      </Typography>
    </Container>
    // <footer className="border-top border-4 border-primary me-auto w-100">
    //   <Container>
    //     <Row className="text-start py-5" xs={{ cols: 1 }} lg={{ cols: 2 }}>
    //       <Col className="mt-2">
    //         <div className="fs-5 text-start text-uppercase fw-light mb-4">
    //           <div className="d-flex justify-content-start gap-3">
    //             <i className="bi-envelope"></i>
    //             Contact
    //           </div>
    //         </div>
    //         <address className="text-start fw-light">
    //           <span className="fw-bold fs-5">'t Dorp</span>
    //           <br></br>
    //           Baardegem-dorp 33,
    //           <br></br>
    //           9310 Baardegem
    //           <br></br>
    //           <a
    //             className="text-decoration-none text-reset"
    //             href="tel:+32478934521"
    //           >
    //             0478 93 45 21
    //           </a>
    //           <br></br>
    //           <br></br>
    //           BE 0863.088.875
    //           <br></br>
    //           <a className="link-primary" href="mailto:info@tdorp-baardegem.be">
    //             info@tdorp-baardegem.be
    //           </a>
    //         </address>
    //         <a
    //           className="text-dark"
    //           href="https://www.facebook.com/FrituurBroodjesBaardegem"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="36"
    //             height="36"
    //             fill="currentColor"
    //             className="bi bi-facebook"
    //             viewBox="0 0 16 16"
    //           >
    //             <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
    //           </svg>
    //         </a>
    //       </Col>
    //       <Col className="mt-2">
    //         <HoursComponent hours={hours} />
    //       </Col>
    //     </Row>

    //     <p className="text-center fw-lighter">
    //       <small>&copy; {yearNow} - BreckxIT</small>
    //     </p>
    //   </Container>
    // </footer>
  )
}

export default FooterComponent
