import React from "react"
import Container from "@mui/material/Container"
import Logo from "../assets/img/logo-dorp.png"
import { Link } from "react-router-dom"

const Header = () => {
  return (
    <Container
      maxWidth={false}
      sx={{ p: 2, borderBottom: 2, borderColor: "primary.main" }}
    >
      <Link to="/">
        <img src={Logo} width="100" height="100" className="p-4" alt="Logo" />
      </Link>
    </Container>
  )
}

export default Header
