import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import CashImg from "../assets/img/cash.png"
import { Button } from "@mui/material"
import { useDispatch } from "react-redux"
import { setSelectedZone } from "../store/zones"
import { useNavigate } from "react-router-dom"

const WelcomeComponent = ({ zones }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div className="text-secondary my-5 py-4">
      <Typography variant="h3" sx={{ fontWeight: "700", textAlign: "center" }}>
        Welkom bij frituur 't dorp
      </Typography>
      <Typography
        variant="h6"
        sx={{ textAlign: "center", marginY: 4, fontWeight: "300" }}
      >
        Wij leveren uw frietjes aan huis in de volgende gemeentes. Kies uw
        gemeente om verder te gaan
      </Typography>
      <Grid
        container
        spacing={2}
        wrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        {zones.map((z) => (
          <Grid item key={z.id} sx={{ width: 1 / 3 }}>
            <Button
              size="large"
              variant="contained"
              sx={{ width: 1 }}
              onClick={() => {
                dispatch(setSelectedZone(z))
                navigate("catalog")
              }}
            >
              {z.label}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="h6"
        sx={{ textAlign: "center", marginY: 4, fontWeight: "300" }}
      >
        Wij accepteren volgende betaalmethodes:
      </Typography>
      <Grid container alignItems="center" justifyContent="center" spacing={4}>
        <Grid item>
          <svg
            width="40"
            height="32"
            viewBox="0 0 32 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.096 0.5H27.904C29.8862 0.5 31.5 2.12098 31.5 4.12903V19.871C31.5 21.879 29.8862 23.5 27.904 23.5H4.096C2.11376 23.5 0.5 21.879 0.5 19.871V4.12903C0.5 2.12098 2.11376 0.5 4.096 0.5Z"
              fill="white"
              stroke="#E6E6E6"
            />
            <path
              d="M9 17C12.6164 17 14.1918 14.411 16 12H4V17H9Z"
              fill="url(#paint0_linear)"
            />
            <path
              d="M23 7C19.3836 7 17.8082 9.58904 16 12H28V7H23Z"
              fill="url(#paint1_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="6.42722"
                y1="14.4555"
                x2="15.2057"
                y2="11.1949"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#005AB9" />
                <stop offset="1" stopColor="#1E3764" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="16.7265"
                y1="12.2401"
                x2="26.0475"
                y2="9.06004"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FBA900" />
                <stop offset="1" stopColor="#FFD800" />
              </linearGradient>
            </defs>
          </svg>
        </Grid>
        <Grid item>
          <img src={CashImg} style={{ height: "32px" }} alt="Cash logo" />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          marginY: 4,
          fontWeight: "300",
          fontStyle: "italic",
        }}
      >
        " Zin in lekkere en krokante frietjes? Dan ben je bij 't Dorp aan het
        juiste adres. We zullen je verwennen met allerlei lekkernijen. Kom eens
        proeven! "
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "right", marginY: 4, fontWeight: "300" }}
      >
        - Team t'dorp
      </Typography>
    </div>
  )
}

export default WelcomeComponent
