import { createSlice } from "@reduxjs/toolkit"
import hash from "object-hash"

const initialCartState = {
  items: [],
  coupon: null,
  totalPrice: 0,
  totalItems: 0,
}

const calculateTotalPrice = (items) => {
  return items.reduce((acc, current) => acc + current.price * current.qty, 0)
}

const calculateTotalItems = (items) => {
  return items.reduce((acc, current) => acc + current.qty, 0)
}

const cartSlice = createSlice({
  name: "cart",
  initialState: initialCartState,
  reducers: {
    addProduct: (state, action) => {
      const item = action.payload

      const hashOfItem = hash({
        id: item.id,
        name: item.name,
        size: item.size,
        extras: item.extras,
      })

      const indexFound = state.items.findIndex((it) => it.id === hashOfItem)

      if (indexFound > -1) {
        state.items[indexFound].qty += item.qty
      } else {
        state.items.push({ ...item, id: hashOfItem })
      }

      state.items = state.items.sort((a, b) => {
        if (a.categoryOrder < b.categoryOrder) {
          return -1
        }
        if (a.categoryOrder > b.categoryOrder) {
          return 1
        }

        if (a.order < b.order) {
          return -1
        }
        if (a.order > b.order) {
          return 1
        }

        return 0
      })

      state.totalItems = calculateTotalItems(state.items)
      state.totalPrice = calculateTotalPrice(state.items)
    },
    removeProduct: (state, action) => {
      const id = action.payload
      state.items = state.items.filter((it) => it.id !== id)
      state.totalItems = calculateTotalItems(state.items)
      state.totalPrice = calculateTotalPrice(state.items)
    },
    setCoupon: (state, action) => {
      state.coupon = action.payload
    },
    removeCoupon: (state, action) => {
      state.coupon = null
    },
    clearCart: () => initialCartState,
  },
})

const { actions, reducer } = cartSlice
export const { addProduct, removeProduct, setCoupon, removeCoupon, clearCart } =
  actions
export default reducer
