import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

const firebaseConfig = {
  apiKey: "AIzaSyC9zmhsO8ajVXYW9AxN28I1xceJr3poThw",
  authDomain: "tdorp-f74b9.firebaseapp.com",
  projectId: "tdorp-f74b9",
  storageBucket: "tdorp-f74b9.appspot.com",
  messagingSenderId: "768674363805",
  appId: "1:768674363805:web:e49b44924641c0f8941774",
  measurementId: "G-7E40K01QJ3",
}

const app = initializeApp(firebaseConfig)

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6LfMVSIiAAAAADR3b1SvkzbGk8RZHxIEMm5Jaj2g"),
//   isTokenAutoRefreshEnabled: true,
// })

export const auth = getAuth(app)
export const db = getFirestore(app)
