import { Box, Button, Container, Typography } from "@mui/material"
import React from "react"
import CartContent from "../components/CartContent"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import OrderForm from "../components/OrderForm"
import { useFormik } from "formik"
import * as Yup from "yup"
import PaymentMethod from "../components/PaymentMethod"

const validationSchema = Yup.object({
  firstName: Yup.string().required("Voornaam is verplicht"),
  lastName: Yup.string().required("Achternaam is verplicht"),
  email: Yup.string()
    .email("Ongeldig e-mailadres")
    .required("E-mail is verplicht"),
  phone: Yup.string()
    .matches(
      /^(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/,
      "Moet een geldig telefoon of gsm nummer zijn."
    )
    .required("Telefoonnummer is verplicht"),
  street: Yup.string().required("Straat is verplicht"),
  number: Yup.string().required("Nummer is verplicht"),
  payment: Yup.string().required("Betaalmethode is verplicht"),
})

const ConfirmScreen = () => {
  const { totalPrice } = useSelector((state) => state.cart)
  const { label, postalCode } = useSelector((state) => state.zones.selectedZone)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      street: "",
      number: "",
      bus: "",
      postalCode: postalCode,
      city: label,
      comments: "",
      payment: "cash",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  return (
    <Container sx={{ my: 4 }}>
      <Button startIcon={<ChevronLeftIcon />} onClick={() => navigate(-1)}>
        Verder winkelen
      </Button>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            mb: 2,
            borderBottom: 2,
            borderColor: "primary.main",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "200",
              textTransform: "uppercase",
            }}
          >
            Winkelmandje
          </Typography>
        </Box>

        <CartContent isEditable={false} />
        <Typography
          variant="h4"
          sx={{ fontWeight: "300", mt: 2, textAlign: "right" }}
        >
          {(totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            mb: 2,
            borderBottom: 2,
            borderColor: "primary.main",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "200",
              textTransform: "uppercase",
            }}
          >
            Gegevens
          </Typography>
        </Box>
        <OrderForm formik={formik} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            mb: 2,
            borderBottom: 2,
            borderColor: "primary.main",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "200",
              textTransform: "uppercase",
            }}
          >
            Betaling
          </Typography>
        </Box>
        <PaymentMethod formik={formik} />
      </Box>
      <Box sx={{ mt: 2, display: "flex", gap: 2, justifyContent: "flex-end" }}>
        <Button type="reset" size="large" variant="outlined" color="secondary">
          Annuleer
        </Button>
        <Button onClick={formik.handleSubmit} variant="contained" size="large">
          Bestel
        </Button>
      </Box>
    </Container>
  )
}

export default ConfirmScreen
