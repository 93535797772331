import React from "react"

import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

import MinusIcon from "@mui/icons-material/Remove"
import PlusIcon from "@mui/icons-material/Add"
import DialogHeader from "./DialogHeader"

const QtyStepper = ({ qty, setQty }) => {
  return (
    <Box sx={{ my: 3 }}>
      <DialogHeader label="Aantal" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <IconButton
          size="large"
          disabled={qty === 1}
          onClick={() => qty > 0 && setQty((prevQty) => prevQty - 1)}
        >
          <MinusIcon />
        </IconButton>
        <Typography variant="h5">{qty}</Typography>
        <IconButton
          size="large"
          onClick={() => setQty((prevQty) => prevQty + 1)}
        >
          <PlusIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default QtyStepper
