import { onAuthStateChanged } from "firebase/auth"
import React, { createContext, useContext, useEffect, useState } from "react"
import { auth } from "../firebase"

const AuthStateContext = createContext({ user: null, isLoading: false })
export const useAuthState = () => useContext(AuthStateContext)

export const AuthStateProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
      setIsLoading(false)
    })

    return () => unsubscribe()
  }, [])

  return (
    <AuthStateContext.Provider value={{ user: user, isLoading: isLoading }}>
      {children}
    </AuthStateContext.Provider>
  )
}
