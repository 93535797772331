import React, { useEffect, useState, forwardRef } from "react"

import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Tab from "@mui/material/Tab"
import Tabs, { tabsClasses } from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"

import { Element, Link } from "react-scroll"
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore"

import ProductComponent from "./ProductComponent"
import { db } from "../firebase"
import ExtrasDialog from "./ExtrasDialog"

const SLink = forwardRef((props, ref) => <Link {...props} />)

const LinkTab = (props) => {
  return (
    <Tab
      component={SLink}
      onClick={(event) => event.preventDefault()}
      {...props}
    />
  )
}

const CategoryListGroup = () => {
  const [catalog, setCatalog] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedIdx, setSelectedIdx] = useState(0)
  const [catalogIsLoading, setCatalogIsLoading] = useState(false)
  const [extrasDialogOpen, setExtrasDialogOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  useEffect(() => {
    setCatalogIsLoading(true)
    console.log("Query firestore")
    const q = query(
      collection(db, "products"),
      where("inCatalog", "==", true),
      where("isEnabled", "==", true),
      orderBy("categoryOrder", "asc"),
      orderBy("order", "asc")
    )

    const unsubscribe = onSnapshot(q, (snap) => {
      const data = snap.docs.map((ds) => {
        return { ...ds.data(), id: ds.id }
      })
      setCatalog(data)
      setCategories([...new Set(data.map((c) => c.category))])
      setCatalogIsLoading(false)
    })

    return () => unsubscribe()
  }, [])

  // useEffect(() => {
  //   if (selectedProduct !== null) {
  //     setExtrasDialogOpen(true)
  //   }
  // }, [selectedProduct])

  if (catalogIsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={80} disableShrink />
      </Box>
    )
  }

  return (
    <>
      <ExtrasDialog
        open={extrasDialogOpen}
        setOpen={setExtrasDialogOpen}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      />
      <Container
        sx={{
          bgcolor: "white",
          position: "sticky",
          overflow: "visible",
          top: 0,
          py: 3,
          zIndex: 100,
          mx: "auto",
          mt: 3,
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          indicatorColor="primary"
          value={selectedIdx}
          onChange={(_, newValue) => {
            setSelectedIdx(newValue)
          }}
          aria-label="categorie tabs"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {categories.map((c, idx) => (
            <LinkTab
              key={c}
              to={c}
              spy={true}
              duration={300}
              offset={-120}
              hashSpy={true}
              ignoreCancelEvents={true}
              onSetActive={(ev) => setSelectedIdx(idx)}
              label={c}
            />
          ))}
        </Tabs>
      </Container>
      <Container>
        {categories.map((c, idx) => (
          <Element key={c} id={c} className="element">
            <Box sx={{ mt: 5 }}>
              <Box
                sx={{
                  borderBottom: 2,
                  borderColor: "primary.main",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "200",
                    textTransform: "uppercase",
                  }}
                >
                  {c}
                </Typography>
              </Box>
              <Stack spacing={5} py={4}>
                {catalog
                  .filter((p) => p.category === c)
                  .map((p) => (
                    <ProductComponent
                      key={p.id}
                      product={p}
                      setSelectedProduct={setSelectedProduct}
                      setExtrasDialogOpen={setExtrasDialogOpen}
                    />
                  ))}
              </Stack>
            </Box>
          </Element>
        ))}
      </Container>
    </>
  )
}

export default CategoryListGroup
