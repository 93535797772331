import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material"
import React from "react"

const PaymentMethod = ({ formik }) => {
  const { values, setFieldValue } = formik

  return (
    <FormControl component="fieldset" sx={{ width: "100%" }}>
      <FormLabel component="legend">Betaalmethode</FormLabel>
      <RadioGroup
        sx={{ gap: 2 }}
        aria-label="payment"
        name="payment"
        value={values.payment}
        onChange={(event) =>
          setFieldValue("payment", event.currentTarget.value)
        }
      >
        <Paper sx={{ p: 2 }}>
          <FormControlLabel value="cash" control={<Radio />} label="Cash" />
        </Paper>
        <Paper sx={{ p: 2 }}>
          <FormControlLabel value="online" control={<Radio />} label="Online" />
        </Paper>
      </RadioGroup>
    </FormControl>
  )
}

export default PaymentMethod
