import { configureStore } from "@reduxjs/toolkit"
import { loadState, saveState } from "./localStorage"

import zoneReducer from "./zones/index"
import cartReducer from "./cart/index"

import debounce from "debounce"

export const store = configureStore({
  devTools: true,
  reducer: {
    zones: zoneReducer,
    cart: cartReducer,
  },
  preloadedState: loadState(),
})

store.subscribe(
  debounce(() => saveState(store.getState())),
  1000
)
